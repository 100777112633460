import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "antd";
import { useMutation } from "@apollo/client";

import CollectGraphicsHelpers from "../helpers";
import { Icons } from "components/layout";
import { Localize } from "components/service";
import { useNotification } from "components/use-hooks";
import { CRAFT_CHANGE_STATUS } from "graphql/mutation/craft-gql";


const CollectGraphicsPreviewBlock = ({
    craftId,
    status,
    stand,
    withStandLink = false,
    onStatusChange = () => { }
}) => {
    const location = useLocation();
    const label = CollectGraphicsHelpers.Status.Label(status);
    const { nError, nSuccess } = useNotification();

    const [ craftChangeStatus, { loading } ] = useMutation(CRAFT_CHANGE_STATUS, {
        update(cache, { data }) {
            const {
                craftUpdateStatus: {
                    label,
                    message,
                    craft
                }
            } = data;

            if (craft && craft.status)
            {
                onStatusChange(craft.status);
            }

            nSuccess(label, message);
        }
    });

    const handleStatusChange = () => {
        if (!craftId || loading) return;

        craftChangeStatus({
            variables: {
                id: craftId,
                status: status === "done" ? "open" : "done"
            }
        }).catch((error) => nError(error));
    };


    return (
        <div className={ `collect-graphics-preview-block ${ status }` }>
            <div className="status-wrap">
                <Button onClick={ handleStatusChange } disabled={ loading || !craftId }>
                    { status === "done" ?
                        <Icons.Check type="double" className="collect-graphics-icon" loading={ loading } /> :
                        <Icons.Check className="collect-graphics-icon" loading={ loading } />
                    }
                    <Localize wrapClass="status" wrap>
                        { label }
                    </Localize>
                </Button>
            </div>
            <div className="count"></div>
            <div className="action">
                { withStandLink &&
                    <Link
                        className="link-button-ghost dark"
                        to={ {
                            pathname: `/stands/${ stand.id }`,
                            state: {
                                from: location.pathname,
                                returnPagePosition: window.scrollY
                            }
                        } }
                    >
                        <Icons.Stand />
                        <Localize>CRAFT.Button_Text_Stand</Localize>
                    </Link>
                }

                <Button
                    className="link-button-ghost dark"

                >
                    { stand?.shelf_number ? (
                        <span style={ { color: '#44D13F' } }>
                            <Localize>CRAFT.Button_Text_ShelfNumber</Localize>&nbsp;<span>{ stand.shelf_number }</span>
                        </span>
                    ) : (
                        <span>
                            <Localize>CRAFT.Button_Text_ShelfNumber</Localize>&nbsp;—
                        </span>
                    ) }
                </Button>
            </div>
        </div>
    );
}

export default CollectGraphicsPreviewBlock;