import React from "react";

import CraftHelpers from "../helpers";
import Job from "components/job";
import Localize, { localizeIt } from "components/service/localize";


const craftOverviewCountTypesHelper = [
    {
        label    : <Localize>OVERVIEW_TYPES.Label_InTotal</Localize>,
        type     : "total",
        route  : "/stands/",
        getFilter : ({ craftType }) => {

            return {
                filter : {
                    whereCrafts: {
                        craftType :  {
                            label    :
                                localizeIt(
                                    "JOB.Filter_Params_CraftType",
                                    { withFilter : CraftHelpers.Types.list( craftType )}
                                ),
                            column   : "TYPE",
                            operator : "EQ",
                            value    : craftType,
                        }
                    },
                    activeFilters : 1
                }
            }
        }
    },
    {
        label    : <Localize>OVERVIEW_TYPES.Label_Finished</Localize>,
        type     : "finished",
        route  : "/stands/",
        getColor : (count) => count > 0 ? "color-success" : "color-disabled",
        getFilter : ({ craftType }) => {
            const status = craftType === 'collect_graphics' ? "done" : "completed";
            const statusLabel = craftType === 'collect_graphics'
                ? localizeIt("CRAFT.Status_Text_Done")
                : localizeIt("CRAFT.Status_Text_Completed");

            return {
                filter : {
                    whereCrafts: {
                        craftStatus: {
                            label: statusLabel,
                            column: "STATUS",
                            operator: "EQ",
                            value: status
                        },
                        craftType :  {
                            label    :
                                localizeIt(
                                    "JOB.Filter_Params_CraftType",
                                    { withFilter : CraftHelpers.Types.list( craftType )}
                                ),
                            column   : "TYPE",
                            operator : "EQ",
                            value    : craftType,
                        }
                    },
                    activeFilters : 2
                }
            }
        }
    },
    {
        label    : <Localize>OVERVIEW_TYPES.Label_Open</Localize>,
        type     : "open",
        route  : "/stands/",
        getColor : (count) => count > 0 ? "color-warning" : "color-disabled",
        getFilter : ({ craftType }) => {
            const status = craftType === 'collect_graphics' ? "open" : "in_progress";
            const statusLabel = craftType === 'collect_graphics'
                ? localizeIt("CRAFT.Status_Text_Open")
                : localizeIt("CRAFT.Status_Text_InProgress");

            return {
                filter : {
                    whereCrafts: {
                        craftStatus: {
                            label: statusLabel,
                            column: "STATUS",
                            operator: "EQ",
                            value: status
                        },
                        craftType :  {
                            label    :
                                localizeIt(
                                    "JOB.Filter_Params_CraftType",
                                    { withFilter : CraftHelpers.Types.list( craftType )}
                                ),
                            column   : "TYPE",
                            operator : "EQ",
                            value    : craftType,
                        }
                    },
                    activeFilters : 2
                }
            }
        }
    },
    {
        label     : <Localize>OVERVIEW_TYPES.Label_Claims</Localize>,
        type      : "claims",
        route     : "/jobs/",
        getColor  : (count) => count > 0 ? "color-warning" : "color-success",
        getFilter : ({ craftType }) => {

            return {
                filter : {
                    where: {
                        status : {
                            label    :
                                Job.Helpers.Status.arrToLabelStr(
                                    { jobStatuses : ["waiting"]}
                                ),
                            column   : "STATUS",
                            operator : "IN",
                            value    : ["waiting"],
                        },
                        craftType :  {
                            label    :
                                localizeIt(
                                    "JOB.Filter_Params_CraftType",
                                    { withFilter : CraftHelpers.Types.list( craftType )}
                                ),
                            column   : "CRAFT_TYPE",
                            operator : "EQ",
                            value    : craftType,
                        },
                        type : {
                            label    :
                                Job.Helpers.NameByType.localized({ jobTypes: ["all_claims"] }),
                            column   : "TYPE",
                            operator : "IN",
                            value    : ["claims"],
                        }
                    },
                    activeFilters : 3
                }
            }
        }
    },
    {
        label     : <Localize>OVERVIEW_TYPES.Label_LastMinute</Localize>,
        type      : "last_min",
        route     : "/jobs/",
        getColor  : (count) => count > 0 ? "color-warning" : "color-success",
        getFilter : ({ craftType }) => {

            return {
                filter : {
                    where: {
                        status : {
                            label    :
                                Job.Helpers.Status.arrToLabelStr(
                                    { jobStatuses : ["waiting"]}
                                ),
                            column   : "STATUS",
                            operator : "IN",
                            value    : ["waiting"],
                        },
                        craftType :  {
                            label    :
                                localizeIt(
                                    "JOB.Filter_Params_CraftType",
                                    { withFilter : CraftHelpers.Types.list( craftType )}
                                ),
                            column   : "CRAFT_TYPE",
                            operator : "EQ",
                            value    : craftType,
                        },
                        type : {
                            label    :
                                Job.Helpers.NameByType.localized({ jobTypes: ["last_minutes"] }),
                            column   : "TYPE",
                            operator : "IN",
                            value    : ["last_minutes"],
                        }
                    },
                    activeFilters : 3
                }
            }
        }
    },
    {
        label    : <Localize>OVERVIEW_TYPES.Label_Overdue</Localize>,
        type     : "overdue",
        route  : "/stands/",
        getColor : (count) => count > 0 ? "color-error" : "color-success",
        getFilter : ({ craftType }) => {

            return {
                filter : {
                    whereCrafts: {
                        craftStatus: {
                            label: localizeIt("CRAFT.Status_Text_Overdue"),
                            column: "STATUS",
                            operator: "EQ",
                            value: "overdue"
                        },
                        craftType :  {
                            label    :
                                localizeIt(
                                    "JOB.Filter_Params_CraftType",
                                    { withFilter : CraftHelpers.Types.list( craftType )}
                                ),
                            column   : "TYPE",
                            operator : "EQ",
                            value    : craftType,
                        }
                    },
                    activeFilters : 2
                }
            }
        }
    },
];

export default craftOverviewCountTypesHelper;