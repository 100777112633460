import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { CRAFT_CHANGE_STATUS } from "graphql/mutation/craft-gql";

import CraftGrids from "../grids";
import { useNotification } from "components/use-hooks";
import { Localize } from "components/service";
import { Icons } from "components/layout";


const CraftChangeStatusBtn = ({ id, status, type, justButton = false, callback = () => { } }) => {

    const { nError, nSuccess } = useNotification();
    const [ craftChangeStatus, { loading }] = useMutation( CRAFT_CHANGE_STATUS,
        {
            update(cache, { data }) {

                const {
                    craftUpdateStatus : {
                        label,
                        message
                    }
                } = data;

                callback();

                nSuccess( label, message );
            }
        }
    );

    const Wrap = ( { children, ...props } ) => {
        if(justButton){
            return(
                <Button
                    {...props}
                    type="primary"
                >
                    { children }
                </Button>
            )
        }

        return(
            <button {...props}>{ children }</button>
        )
    }

    const isCollectGraphics = type === "collect_graphics";
    const nextStatus = isCollectGraphics
        ? (status !== "done" ? "done" : "open")
        : (status !== "completed" ? "completed" : "in_progress");

    const buttonClassName = isCollectGraphics
        ? `craft-change-status-btn status ${status !== "done" ? "done" : "open"} collect-graphics-type`
        : `craft-change-status-btn status ${status !== "completed" ? "completed" : "in-progress"}`;

    return(
        <Wrap
            onClick = {
                () => {
                    if(!loading){
                        craftChangeStatus({
                            variables: {
                                id,
                                status: nextStatus
                            }
                        }).catch((error) => nError( error ))
                    }
                }
            }
            className = { buttonClassName }
            data-type = { type }
        >
            { !justButton ?
                <>
                    <CraftGrids.Single
                        extraClass = { isCollectGraphics
                            ? (status !== "done" ? "done" : "open")
                            : (status !== "completed" ? "completed" : "in_progress") }
                        type = { type }
                        loading = { loading }
                    />
                    { isCollectGraphics ?
                        (status !== "done" ?
                            <Localize>CRAFT.Button_Text_MarkDone</Localize> :
                            <Localize>CRAFT.Button_Text_MarkOpen</Localize>)
                        :
                        (status !== "completed" ?
                            <Localize>CRAFT.Button_Text_MarkDone</Localize> :
                            <Localize>CRAFT.Button_Text_MarkInProgress</Localize>)
                    }
                </> :
                <>
                    { isCollectGraphics ?
                        (status !== "done" ?
                            <>
                                <Icons.Control loading = { loading } className="collect-graphics-icon" />
                                <Localize>CRAFT.Status_Text_Open</Localize>
                            </> :
                            <>
                                <Icons.Check type="double" loading = { loading } className="collect-graphics-icon" />
                                <Localize>CRAFT.Status_Text_Collected</Localize>
                            </>
                        ) :
                        (status !== "completed" ?
                            <>
                                <Icons.Control loading = { loading } />
                                <Localize>CRAFT.Button_ChangeStatus_Done</Localize>
                            </> :
                            <>
                                <Icons.InProgress loading = { loading }/>
                                <Localize>CRAFT.Button_ChangeStatus_InProgress</Localize>
                            </>
                        )
                    }
                </>
            }
        </Wrap>
    )
}

export default CraftChangeStatusBtn